import React from 'react';
import '../styles/webhook.css'

const ResponseComponent = (props) => {
    const { webhook_type } = props;
    const organisationId = localStorage.getItem('doosra-biz-organisation-id');
    let web_hook_data = "";
    let web_hook_data_dail_on = "";
    let web_hook_data_title = "";
    let number_masking_response_data = "";
    if (webhook_type && webhook_type.length > 0) {
        if (webhook_type[0] === 'CALL') {
            web_hook_data_title = 'Sample Webhook Incoming Call Data';
            web_hook_data =
                `{
    "organisation_id" : "89311a60-ee25-11ec-xxxxxx",
    "call_type": "incoming"
    "caller_number": "905293XXXX",
    "receiver_number": "967650XXXX",
    "did_number": "91806973XXXX",
    "received_at" : "2023-02-28T06:48:01.000Z",
    "ended_at" : "2023-02-28T06:49:10.000Z",
    "call_id" : "65015fbd9c9a62XXXXXXX",
    "call_status" : "answered" /  "notanswered",
    "call_duration" : "00:01:09",
    "call_recording_url":"https://doosra-ivr-voicemails.s3.ap-south-1.amazonaws.com/.....",
    "agent_name": "kumarxxxxx",
    }`;
            web_hook_data_dail_on =
                `{
"organisation_id" : "89311a60-ee25-11ec-xxxxxx",
"call_type": "incoming",
"caller_number": "905293XXXX",
"receiver_number": "967650XXXX",
"did_number": "91806973XXXX",
"received_at" : "2023-02-28T06:48:01.000Z",
"call_id" : "65015fbd9c9a62XXXXXXX",
"agent_name": "kumarxxxxx",
}`;
        }
        if (webhook_type[0] === 'OUTGOING_CALL') {
            web_hook_data_title = 'Sample Webhook Out Going Call Data';

            web_hook_data =
                `{
    "organisation_id" : "89311a60-ee25-11ec-xxxxxx",
    "call_type": "outgoing"
    "caller_number": "905293XXXX",
    "receiver_number": "967650XXXX",
    "did_number": "91806973XXXX",
    "received_at" : "2023-02-28T06:48:01.000Z",
    "ended_at" : "2023-02-28T06:49:10.000Z",
    "call_id" : "65015fbd9c9a62XXXXXXX",
    "call_status" : "answered" /  "notanswered",
    "call_duration" : "00:01:09",
    "call_recording_url":"https://doosra-ivr-voicemails.s3.ap-south-1.amazonaws.com/.....",
    "agent_name": "kumarxxxxx",
}`;
        }
        if (webhook_type[0] === 'SMS') {
            web_hook_data_title = 'Sample Webhook SMS Data';
            web_hook_data =
                `{
    "s_mobile_no" : "9912345678",
    "v_mobile_no" : "919912345678",
    "message" : "test msg",
    "created_at" : "2023-02-28T06:49:10.000Z"
}`;
        }
        if (webhook_type[0] === 'VOICE_API') {
            web_hook_data_title = 'Sample Webhook Voice Data';
            web_hook_data =
                `{
    "call_id" : "6566ce99a46d4ce5dc6e2385",
    "campaign_id" : "6566ce01a46d4ce5dc6e22c2",
    "dtmf_key" : "1"
    "status" : "ANSWERED",
}`;
        }
        if (webhook_type[0] === 'NUMBER_MASKING') {
            web_hook_data_title = 'Sample Webhook Request Data';
            web_hook_data =
                `{
    "caller_number":"99889xxxxx",
    "did":"918645658689",
    "call_id":"1ascaba13abcaav"
}`;
            number_masking_response_data =
                `{
    "response": {
        "call_forwarding_number": "87123xxxxx",
        "call_timeout": 0
    }
}`;
        }
        if (webhook_type[0] === 'NM_CALL_DETAILS') {
            web_hook_data_title = 'Sample Webhook Number Masking Call Data';
            web_hook_data =
                `{

    "organisation_id": "89311a60-ee25-11ec-xxxxxx",
    "caller_number": "905293XXXX",
    "receiver_number": "967650XXXX",
    "did_number": "91806973XXXX",
    "received_at": "2023-02-28T06:48:01.000Z",
    "ended_at": "2023-02-28T06:49:10.000Z",
    "call_id": "65015fbd9c9a62XXXXXXX",
    "call_status": "answered", // or "not_answered"
    "call_duration": "00:01:09",
    "call_recording_url": "https://doosra-ivr-voicemails.s3.ap-south-1.amazonaws.com/.....",
}`;
        }
    }
    return (
        <div className='Response-Card'>
            {(webhook_type[0] === 'CALL') ? (
                <div style={{ display: 'flex' }}>
                    <pre style={{ width: '50%' }}>
                        <div className="webhookTitle" style={{ marginBottom: 10 }}>Sample Dialed on Agent Webhook</div>
                        <code>
                            <span className='JSON-Obj'>{web_hook_data_dail_on}</span>
                        </code>
                    </pre>
                    <pre style={{ width: '50%' }}>
                        <div className="webhookTitle" style={{ marginBottom: 10 }}>Sample Call Completion Webhook</div>

                        <code>
                            <span className='JSON-Obj'>{web_hook_data}</span>
                        </code>
                    </pre>

                </div>
            ) : (webhook_type[0] === 'NUMBER_MASKING') ?
                (
                    <div style={{ display: 'flex' }}>
                        <pre style={{ marginRight: 10 }}>
                            <div className="webhookTitle" style={{ marginBottom: 10 }}>{web_hook_data_title}</div>
                            <code>
                                <span className='JSON-Obj'>{web_hook_data}</span>
                            </code>
                        </pre>
                        <pre>
                            <div className="webhookTitle" style={{ marginBottom: 10 }}>Sample Webhook Response Data</div>

                            <code>
                                <span className='JSON-Obj'>{number_masking_response_data}</span>
                                <p><strong>Note: </strong>call_timeout value is in seconds and Value 0 (zero) refers to no time out</p>
                            </code>
                        </pre>

                    </div>
                )
                : (
                    <>
                        <div className="webhookTitle" style={{ marginBottom: 10 }}>{web_hook_data_title}</div>

                        <pre>
                            <code>
                                <span className='JSON-Obj'>
                                    {web_hook_data}
                                </span>
                            </code>
                        </pre>
                    </>
                )}
        </div>
    )
}

export default ResponseComponent