import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';

import { DELETE, GET, POST, PUT } from '../../../services/HttpRequests';
import AssignUserPopup from '../components/AssignUserPopup';
import DeactivateNumberConfirmationPopup from '../components/DeactivateNumberConfirmationPopup';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import ReassignUserPopup from '../components/ReassignUserPopup';
import SearchBar from '../../../common/component/searchbar/SearchBarComponent';
import Sidebar from '../../../common/componentV2/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import UnassignConfirmationPopup from '../components/UnassignConfirmationPopup';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import { commonStyle } from '../../../assets/css/css';
import DataTable from '../../../common/component/table/DataTable';
import { getTopBarData } from '../../../actions/topBarActions';
import { isSuperAdminWithPermission } from '../../../utility/utility';
import {
  ADMIN_VIRTUAL_NUMBERS_TAB,
  ADMIN_OUTGOING_NUMBERS_TAB,
  ADMIN_SEARCH_VIRTUAL_NUMBERS_BUTTON,
} from '../../../common/posthog/events';
import { logPostHogEvent } from '../../../common/posthog/posthog';
import { FaBan, FaCheckCircle } from "react-icons/fa";
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import './DIDNumbers.css';

const orgUrl = process.env.REACT_APP_ORGANIZATION_API;
const vmnUrl = process.env.REACT_APP_VMN_API;
const priorityRoutingUrl = process.env.REACT_APP_PRIORITY_ROUTING_API;
const planAvailable = localStorage.getItem('is_plan_available');
class DoosraNumbersPage extends Component {
  constructor() {
    super();
    this.state = {
      status: 'assigned',
      count: 0,
      outgoingNumberCount: 0,
      numbersList: [],
      totalPages: 0,
      currentPage: 1,
      pageSize: 15,
      loading: false,
      searchData: {},
      groups: [],
      latestFreeTrialVmnStatus: '',
      spamPopup: false,
      did: "",
      isSpamPopup: false,
      isHoveredSpam: false,
      isHoveredUnspam: false,
    };
  }

  async componentDidMount() {
    this.getFreshList();
    // this.getDidNumbersList();
  }

  getFreshList = () => {
    this.setState({ loading: true });
    let data = {
      pageNo: this.state.currentPage,
      pageSize: this.state.pageSize,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };

    GET(`${vmnUrl}v1/organisations/numbers`, data)
      .then((res) => {
        return res.data.response;
      })
      .then(async (res) => {
        const allGrupsResponse = await GET(
          `${priorityRoutingUrl}v1/organisations/priority-routing/group/list`,
          {
            page: 0,
            page_size: 100,
            organisationId: localStorage.getItem('doosra-biz-organisation-id'),
          }
        ).then((res) => {
          return res.data.response;
        });

        const list = allGrupsResponse?.items || [];
        const groupList = [];
        list.map((item) => {
          const dids = item?.dids || [];
          if (dids.length <= 0) {
            groupList.push(item);
          }
        });
        this.setState({ groups: groupList });
        this.setState({
          count: res.numbers.count,
          numbersList: res.numbers.docs,
          totalPages: Math.ceil(res.numbers.count / this.state.pageSize),
          pageSize: res.pageSize,
          loading: false,
          searchData: {},
        });
      })
      .catch((err) => {
        if (err) {
          this.setState({ loading: false });
        }
      });
  };

  // getDidNumbersList = () => {
  //   let orgId = localStorage.getItem('doosra-biz-organisation-id');
  //   GET(`${vmnUrl}v1/did/numbers`, {
  //     pageNo: 1,
  //     pageSize: 15,
  //     organisationId: orgId,
  //   })
  //     .then((res) => {
  //       return res.data.response;
  //     })
  //     .then((res) =>
  //       this.setState({ outgoingNumberCount: res?.numbers?.count || 0 })
  //     );
  // };

  seachHandler = (data) => {
    logPostHogEvent(ADMIN_SEARCH_VIRTUAL_NUMBERS_BUTTON);
    data.pageNo = 1;
    data.pageSize = this.state.pageSize;
    data.organisationId = localStorage.getItem('doosra-biz-organisation-id');
    GET(`${vmnUrl}v1/organisations/numbers`, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.setState({
          count: res.numbers.count,
          numbersList: res.numbers.docs,
          totalPages: Math.ceil(res.numbers.count / this.state.pageSize),
          pageSize: res.pageSize,
          searchData: data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  fetchPageData = (value) => {
    let data = {
      pageNo: value,
      pageSize: this.state.pageSize,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    data = { ...this.state.searchData, ...data };

    GET(`${vmnUrl}v1/organisations/numbers`, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.setState({
          count: res.numbers.count,
          numbersList: res.numbers.docs,
          currentPage: value,
          pageSize: res.pageSize,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  assignUserHandler = (numberId, userId) => {
    let data = { user_id: userId, status: 'ASSIGN' };
    this.updateHandler(numberId, data);
  };

  assignGroupHandler = async (numberId, groupId, status = 'ASSIGN') => {
    const data = {
      id: numberId,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      priorityRoutingGroupId: groupId,
      status,
    };

    const response = await PUT(
      `${vmnUrl}v1/organisations/manage-vmn`,
      null,
      data
    );
    if (response) {
      this.getFreshList();
    }
  };

  assignAutoAttendantHandler = async (numberId, autoAttendantId, status) => {
    const data = {
      id: numberId,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      autoAttendantId: autoAttendantId,
      status: status,
    };
    const response = await PUT(
      `${vmnUrl}v1/organisations/manage-vmn`,
      null,
      data
    );

    if (response) {
      this.getFreshList();
    }
  };

  assignAutoAttendantHandler = async (numberId, autoAttendantId, status) => {
    const data = {
      id: numberId,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      autoAttendantId: autoAttendantId,
      status: status,
    };
    const response = await PUT(
      `${vmnUrl}v1/organisations/manage-vmn`,
      null,
      data
    );
    if (response) {
      this.getFreshList();
    }
  };

  reassignUserHandler = (numberId, userId) => {
    let data = { user_id: userId, status: 'REASSIGN' };
    this.updateHandler(numberId, data);
  };

  unassignUserHandler = (numberId, userId) => {
    let data = {
      user_id: userId,
      status: 'DEASSIGN',
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      id: numberId,
    };

    PUT(`${vmnUrl}v1/organisations/manage-vmn`, null, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.getFreshList();
        // let newList = this.state.numbersList.map((item) => {
        //   if (numberId === item._id) {
        //     item.status = 'ACTIVE';
        //     item.user_id = null;
        //     item.user.pop();
        //   }
        //   return item;
        // });
        // this.setState({ numbersList: newList });
        toast.success(`Success`);
      })
      .catch((err) => {
        console.log('unassignUserHandler:', { err });

        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error
        ) {
          toast.error(err.response.data.error.reason);
        }
      });
  };

  unassignGroupHandler = async (numberId, groupId) => {
    const data = {
      vmnId: numberId,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      priorityRoutingGroupId: groupId,
      groupType: 'INCOMINGCALL',
    };
    const response = await DELETE(
      `${priorityRoutingUrl}v1/organisations/priority-routing/group/primaries`,
      null,
      data
    );
    if (response) {
      this.getFreshList();
    }
  };

  updateHandler = (doosraNumberId, data) => {
    PUT(`${vmnUrl}v1/organisations/manage-vmn`, null, {
      ...data,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      id: doosraNumberId,
    })
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.getFreshList();
        // let newNumbersList = this.state.numbersList.map((item) => {
        //   if (res.user.v_mobile_no === item.v_mobile_no) {
        //     item.status = 'ASSIGNED';
        //     if (item.user.length > 0) item.user.pop();
        //     item.user_id = res.user.user_id;

        //     if (data.status === 'REASSIGN') item.user.pop();
        //     item.user.push(res.user);
        //     // console.log()
        //     // }
        //     return item;
        //   } else {
        //     return item;
        //   }
        // });

        // this.setState({ numbersList: newNumbersList });
        toast.success(`Success`);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error
        ) {
          toast.error(err.response.data.error.reason);
        }
      });
  };

  assignedToName = (item) => {
    let name = '';
    if (item.group && item.group.length > 0 && item.group[0].name) {
      name = item.group[0].name;
    } else if (item.user && item.user.length > 0 && item.user[0].zvr_name) {
      name = item.user[0].zvr_name;
    } else if (item.ivr && item.ivr.length > 0 && item.ivr[0].ivr_name) {
      name = item.ivr[0].ivr_name;
    } else if (
      item.autoAttendant &&
      item.autoAttendant.length > 0 &&
      item.autoAttendant[0].name
    ) {
      name = item.autoAttendant[0].name;
    } else if (item?.Callflow?.length > 0) {
      name = item.Callflow[0].ivrName;
    }
    return name.trim();
  };

  sortNumbers = (n1, n2) => {
    return this.assignedToName(n1).toLowerCase() >
      this.assignedToName(n2).toLowerCase()
      ? -1
      : 1;
  };

  switchNumberStatus = (doosraNumberId, value) => {
    let data = {
      status: value,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      id: doosraNumberId,
    };

    PUT(`${vmnUrl}v1/organisations/manage-vmn`, {}, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        let newNumbersList = this.state.numbersList.map((item) => {
          if (res.vmn.v_mobile_no === item.v_mobile_no) {
            if (res.vmn.status === 'ACTIVE') item.status = res.vmn.status;
            if (res.vmn.status === 'INACTIVE') item.status = res.vmn.status;
          }
          return item;
        });
        this.setState({ numbersList: newNumbersList });
        toast.success(`Success`);
      })
      .catch((err) => {
        console.log('err');
      });
  };

  trimNumber = (number) => {
    if (number && number.length == 12) return number.substring(2);
    return number;
  };

  getNumberType = (item) => {
    if (item?.user?.length > 0) {
      return 'Agent';
    }
    if (item?.ivr?.length > 0) {
      return 'IVR';
    }
    if (item?.group?.length > 0) {
      return 'Group';
    }
    if (item?.linked_audio?.length > 0) {
      return 'Audio';
    }
    if (item?.autoAttendant?.length > 0) {
      return 'Auto attendant';
    }
    if (item?.Callflow?.length > 0) {
      return 'Callflow';
    }
    return '-';
  };

  updateFreeTrialVmnStatus = (status) => {
    let data = {
      status,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    PUT(`${vmnUrl}v1/organisations/free-trial-manage-vmn`, null, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.setState({ latestFreeTrialVmnStatus: data.status });
        toast.success(`Success`);
        this.getFreshList();
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error
        ) {
          toast.error(err.response.data.error.reason);
        }
      });
  };

  filterRestGroups = (groupId) => {
    const allGroup = [...this.state.groups];
    const index = allGroup.findIndex((item) => item._id === groupId);
    if (index !== -1) {
      allGroup.splice(index, 1);
    }
    return allGroup;
  };

  checkIfIncomingDid = (services) => {
    const keys = Object.keys(services);
    if (services?.incoming === true) {
      return true;
    }
    return false;
  };
  handleSpam = () => {
    let data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      did: this.state.did,
      spam: this.state.isSpamPopup ? true : false
    };
    this.setState({ spamPopup: false  });
    PUT(`${vmnUrl}v1/did/manage-spam`, null, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        toast.success(`Success`);
        this.getFreshList();
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error
        ) {
          toast.error(err.response.data.error.reason);
        }
      });
  }
  render() {
    let displayData;
    const { isOrgOutboundCallEnabled } = this.props;
    const tabs = [
      {
        title: 'Virtual Numbers',
        active: true,
        subtitle: `${this.state.count}`,
        onClick: () => {
          logPostHogEvent(ADMIN_VIRTUAL_NUMBERS_TAB);
        },
      },
    ];

    // if (isOrgOutboundCallEnabled)
    //   tabs.push({
    //     title: 'Outgoing numbers',
    //     subtitle: `${this.state.outgoingNumberCount}`,
    //     onClick: () => {
    //       logPostHogEvent(ADMIN_OUTGOING_NUMBERS_TAB)
    //       this.props.history.push('/outgoing-virtual-numbers')
    //     },
    //   });

    if (this.state.loading) {
      displayData = <PreLoader />;
    }
    if (this.state.numbersList.length > 0) {
      displayData = this.state.numbersList
        .sort(this.sortNumbers)
        .map((item) => (
          <DataTable.Row>
            <DataTable.Cell style={{ paddingLeft: 25 }}>
              {this.trimNumber(item.v_mobile_no)}
            </DataTable.Cell>
            <DataTable.Cell>{item.status}</DataTable.Cell>
            <DataTable.Cell>
              {item.status === 'ASSIGNED' ? this.assignedToName(item) : '-'}
            </DataTable.Cell>
            <DataTable.Cell>
              {item.status === 'ASSIGNED' ? this.getNumberType(item) : '-'}
            </DataTable.Cell>
            <DataTable.Cell>
              {item.status === 'ASSIGNED' && item?.user?.length > 0
                ? item?.user[0]?.zvr_mobile_no
                : '-'}
            </DataTable.Cell>
            <DataTable.Cell>
              {item.callsCount}
            </DataTable.Cell>
            
            {(planAvailable == 'false' || planAvailable == false) && (
              <DataTable.Cell>
                {item.expire_date ? item.expire_date.substring(0, 10) : '-'}
              </DataTable.Cell>
            )}
            {/* <DataTable.Cell>
              {item.services
                ? Object.keys(item.services)
                  .filter((key) => item.services[key])
                  .map((key) => (
                    <>
                      {key}
                      <br />
                    </>
                  ))
                : '-'}
            </DataTable.Cell> */}
            <DataTable.Cell style={{ textAlign: 'center', width: '30%' }}>
              {item.services ? (
                <>
                  {item.status === 'ASSIGNED' && (
                    <>
                      <ReassignUserPopup
                        nonIncomingDid={!this.checkIfIncomingDid(item.services)}
                        NumToAssign={item.v_mobile_no}
                        groups={this.filterRestGroups(item.routing_group_id)}
                        reassignGroup={(id) =>
                          this.assignGroupHandler(item._id, id, 'REASSIGN')
                        }
                        reassignUser={(userId) =>
                          this.reassignUserHandler(item._id, userId)
                        }
                        reassignAutoAttendant={(autoAttendantId) =>
                          this.assignAutoAttendantHandler(
                            item._id,
                            autoAttendantId,
                            'REASSIGN'
                          )
                        }
                        numberDetails={item}
                        isAgentsEnabled={true}
                        isGroupsEnabled={this.props.callPriorityRoutingStatus}
                        showAutoAttendant={true}
                      />
                      <UnassignConfirmationPopup
                        nonIncomingDid={!this.checkIfIncomingDid(item.services)}
                        unassignUser={() =>
                          this.unassignUserHandler(item._id, item.user_id)
                        }
                        unassignGroup={() =>
                          this.assignGroupHandler(
                            item._id,
                            item.routing_group_id,
                            'DEASSIGN'
                          )
                        }
                        unassignAutoAttendant={() =>
                          this.assignAutoAttendantHandler(
                            item._id,
                            item.autoAttendant[0]._id,
                            'DEASSIGN'
                          )
                        }
                        numberDetails={item}
                      />
                    </>
                  )}
                  {item.status === 'ACTIVE' && (
                    <>
                      <AssignUserPopup
                        NumToAssign={item.v_mobile_no}
                        groups={this.state.groups}
                        assignGroup={(id) =>
                          this.assignGroupHandler(item._id, id, 'ASSIGN')
                        }
                        assignUser={(userId) =>
                          this.assignUserHandler(item._id, userId)
                        }
                        assignAutoAttendant={(autoAttendantId) =>
                          this.assignAutoAttendantHandler(
                            item._id,
                            autoAttendantId,
                            'ASSIGN'
                          )
                        }
                        nonIncomingDid={!this.checkIfIncomingDid(item.services)}
                        isAgentsEnabled={true}
                        showAutoAttendant={true}
                        isGroupsEnabled={this.props.callPriorityRoutingStatus}
                      />

                      {/* <DeactivateNumberConfirmationPopup
                        nonIncomingDid={!this.checkIfIncomingDid(item.services)}
                        deactivate={() =>
                          this.switchNumberStatus(item._id, 'INACTIVE')
                        }
                      /> */}
                    </>
                  )}
                  {item.status === 'INACTIVE' && (
                    <button
                      className={
                        !isSuperAdminWithPermission('EDIT')
                          ? 'DisableButton'
                          : 'ActivateButton'
                      }
                      onClick={() =>
                        this.switchNumberStatus(item._id, 'ACTIVE')
                      }
                      disabled={!isSuperAdminWithPermission('EDIT')}
                    >
                      ACTIVATE
                    </button>
                  )}
                  {item?.didStatus === 'SPAM' ? (
                      <div className="icon-container" style={{ width: "2rem", border: "1px solid black", padding: "5px" }} onClick={() => this.setState({ spamPopup: true, did: this.trimNumber(item.v_mobile_no), isSpamPopup: false })}>
                      <FaCheckCircle  color="green" />
                      <span style={{  minWidth: "5rem"  }}  className="tooltip">UN SPAM</span>
                    </div>
                  ) : 
                    <div className="icon-container" style={{ width: "2rem", border: "1px solid black", padding: "5px" }} onClick={() => this.setState({ spamPopup: true, did: this.trimNumber(item.v_mobile_no), isSpamPopup: true })}>
                      <FaBan  color="red" />
                      <span  className="tooltip">SPAM</span>
                    </div>
                  }      
                </>
              ) : null}
            </DataTable.Cell>
          </DataTable.Row>
        ));
    }
    return (
      <div className={`${this.props.className}`}>
        <Topbar />
        <div style={{ display: 'flex', height: 'calc(100vh - 72px)' }}>
          <Sidebar />
          <div className="ContentContainer">
            <TabSearchBar tabs={tabs}>
              <SearchBar
                type="doosraNumbers"
                disableTitle
                count={this.state.count}
                reset={this.getFreshList}
                search={this.seachHandler}
              />
            </TabSearchBar>
            <DataTable.Table>
              <DataTable.Head>
                <DataTable.CellHead style={{ paddingLeft: 25 }}>
                  Virtual Number
                </DataTable.CellHead>
                <DataTable.CellHead>STATUS</DataTable.CellHead>
                <DataTable.CellHead>NAME</DataTable.CellHead>
                <DataTable.CellHead>ASSIGNED TO</DataTable.CellHead>
                <DataTable.CellHead>PERSONAL NO</DataTable.CellHead>
                <DataTable.CellHead>TOTAL CALLS</DataTable.CellHead>
                {(planAvailable == 'false' || planAvailable == false) && (
                  <DataTable.CellHead>EXPIRE DATE</DataTable.CellHead>
                )}
                {/* <DataTable.CellHead>SERVICES</DataTable.CellHead> */}
                <DataTable.CellHead
                  style={{ textAlign: 'center', width: '28%' }}
                >
                  ACTIONS
                </DataTable.CellHead>
              </DataTable.Head>
              <DataTable.Body>{displayData}</DataTable.Body>
            </DataTable.Table>

            <Popup
              open={this.state.spamPopup}
              closeOnDocumentClick={false}
              closeOnEscape={false}
            >
              <div className="PopupContainer">
                 <div className="PopupHeaderContainer">
                 { this.state.isSpamPopup ? <p>Mark DID as Spam</p> : <p>Mark DID as not spam</p>}
                  <img
                    alt=""
                    className="PopupClose"
                    src={Close}
                    onClick={() => this.setState({ spamPopup: false  })}
                  />
                 </div>
                  <div className="PopupBody">
                    {this.state.isSpamPopup ?
                    <p style={{ fontSize: "1rem", fontWeight: "normal", padding: "1rem" }}>Adding this DID to spam will block it from campaigns and outgoing calls, but incoming calls will still be allowed. Click “Confirm” to proceed.</p>
                    : <p style={{ fontSize: "1rem", fontWeight: "normal", padding: "1rem" }}>Removing this DID from spam will allow it to be used in campaigns and outgoing calls again. Incoming calls were never blocked. Click 'Confirm' to proceed.</p>}
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center"  }}>
                      <button className='ButtonFullWidth' onClick={() => this.handleSpam()}>
                        Confirm
                      </button>
                    </div>
                  </div>
               </div>
            </Popup>

            <Paginator
              currentPage={this.state.currentPage}
              lastPage={this.state.totalPages}
              getInfo={(value) => this.fetchPageData(value)}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  isOrgOutboundCallEnabled: state.topbar.isOrgOutboundCallEnabled,
  callPriorityRoutingStatus: state.topbar.call_priority_routing,
});

export default commonStyle(connect(mapStateToProps, {})(DoosraNumbersPage));
