import { GET } from '../services/HttpRequests';
import * as Constants from '../common/constants';
export const TOPBAR_DATA_SUCCESS = 'TOPBAR_DATA_SUCCESS';
export const USER_PROFILE_DETAILS = 'USER_PROFILE_DETAILS';
export const TOPBAR_VOICE_CREDITS_DATA_SUCCESS =
  'TOPBAR_VOICE_CREDITS_DATA_SUCCESS';
const orgUrl = process.env.REACT_APP_ORGANIZATION_API;
const broadcastUrl = process.env.REACT_APP_BROADCAST_API;
const moment = require('moment-timezone');

export const saveUserProfileDetails = (data) => (dispatch) => {
  console.log('triggered profile details save');
  dispatch({
    type: USER_PROFILE_DETAILS,
    payload: data,
  });
};

export const getTopBarData = () => (dispatch) => {
  GET(`${orgUrl}v1/organisation/basic/details`, {
    organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
  })
    .then((res) => {
      return res.data.response;
    })
    .then((res) => {
      // console.log('data is', res.organisation);
      let balance = 0;
      if (
        res.organisation &&
        res.organisation.balance &&
        res.organisation.balance.amount
      ) {
        balance = res.organisation.balance.amount;
      }
      const data = { ...res.organisation, ...res.globalConfigurations };
      dispatch({
        type: TOPBAR_DATA_SUCCESS,
        data: data,
        balance: balance,
      });

      localStorage.setItem(
        'doosra-biz-organisation-name',
        res.organisation.name
      );
      localStorage.setItem(
        'org-webhook-count',
        res.organisation.max_webhook_count
      );
      const {
        is_sms_webhook_enabled,
        is_call_webhook_enabled,
        is_voice_api_webhook_enabled,
        is_free_form_enabled,
        is_number_masking_enabled,
        is_dialer_widget_enabled,
        pstn_enabled,
        webrtc_enabled,
        is_campaigns_v2_enabled,
        is_static_campaigns_dialer_enabled,
        is_preview_campaigns_dialer_enabled,
        is_predictive_campaigns_dialer_enabled,
        is_analytics_enabled,
        is_ai_intelligence_enabled,
        call_merge_enabled,
        call_transfer_enabled,
        incoming_webrtc_enabled,
        call_merge_transfer_webrtc_enabled
      } = res.organisation.add_ons;

      localStorage.setItem('call_merge_enabled', call_merge_enabled || false);

      localStorage.setItem(
        'incoming_webrtc_enabled',
        incoming_webrtc_enabled || false
      );

      const orgPlanDetails = res.organisation.planDetails;
      if (
        orgPlanDetails
        && orgPlanDetails.product
        && orgPlanDetails.plan
        && orgPlanDetails.tenure
      ) {
        localStorage.setItem(
          'organisation_plan_name',
          (orgPlanDetails.product + "-" + orgPlanDetails.plan + "-" + orgPlanDetails.tenure)
        );
      }

      localStorage.setItem(
        'call_transfer_enabled',
        call_transfer_enabled || false
      );

      localStorage.setItem(
        'call_merge_transfer_webrtc_enabled',
        call_merge_transfer_webrtc_enabled || false
      );

      localStorage.setItem(
        'is_ai_intelligence_enabled',
        is_ai_intelligence_enabled || false
      );

      localStorage.setItem('pstn_enabled', pstn_enabled || false);
      localStorage.setItem(
        'is_campaigns_v2_enabled',
        is_campaigns_v2_enabled || false
      );
      localStorage.setItem(
        'is_analytics_enabled',
        is_analytics_enabled || false
      );

      localStorage.setItem(
        'is_campaigns_v2_static_enabled',
        is_static_campaigns_dialer_enabled || false
      );
      localStorage.setItem(
        'is_campaigns_v2_preview_enabled',
        is_preview_campaigns_dialer_enabled || false
      );

      localStorage.setItem(
        "is_predictive_v2_campaigns_dialer_enabled",
        is_predictive_campaigns_dialer_enabled || false
      )

      localStorage.setItem('webrtc_enabled', webrtc_enabled || false);

      if (res.organisation.free_trial) {
        localStorage.setItem(
          'is_free_trial_enabled',
          res.organisation.free_trial
        );
      }
      if (res.organisation.vmn_status) {
        localStorage.setItem(
          'is_free_trial_vmn_status',
          res.organisation.vmn_status
        );
      }

      if (res.organisation.free_trial_start_date) {
        localStorage.setItem(
          'free_trial_start_date',
          res.organisation.free_trial_start_date
        );
      }

      if (res.organisation.free_trial_end_date) {
        localStorage.setItem(
          'free_trial_end_date',
          res.organisation.expiry_date
        );
      }
      localStorage.setItem(
        'is_kyc_submitted',
        res.organisation.is_kyc_submitted
      );
      localStorage.setItem(
        'is_kyc_completed',
        !!res.organisation.is_kyc_completed
      );
      localStorage.setItem(
        'is_kyc_callback_requested',
        res?.organisation?.KYCDetails?.isCallBackRequested
      );
      localStorage.setItem(
        'is_plan_expiry_email_sent',
        !!res?.organisation?.is_plan_expiry_email_sent
      );

      localStorage.setItem(
        'is_plan_expired',
        !!res.organisation.is_plan_expired
      );
      localStorage.setItem(
        'org_in_grace_period',
        !!res.organisation.org_in_grace_period
      );
      localStorage.setItem(
        'is_freemium_plan_used',
        !!res.organisation.is_freemium_plan_used
      );
      localStorage.setItem(
        'organisation_expiry_date',
        res.organisation.expiry_date
      );
      localStorage.setItem(
        'organisation_nextBillingAt_date',
        res.organisation.next_billing_at || ''
      );

      let inRenewPeriod = false;
      if (res.organisation.next_billing_at) {
        inRenewPeriod = moment(res.organisation.next_billing_at) <= moment();
      }
      localStorage.setItem(
        'organisation_inRenewPeriod',
        inRenewPeriod || false
      );

      if (
        res?.organisation?.planDetails?.tenure &&
        res?.organisation?.next_billing_at
      ) {
        const nextBillingAt = res?.organisation?.next_billing_at;
        const tenure = res?.organisation?.planDetails?.tenure;
        let plan = res?.organisation?.planDetails?.plan;
        const diffDays = moment(nextBillingAt).diff(moment(), 'days');
        let inAdvancePeriod = false;
        if (plan) {
          plan = plan.toLowerCase();
          if (!plan.includes('freemium')) {
            if (tenure === 'MONTHLY') {
              inAdvancePeriod = diffDays <= 7;
            } else {
              inAdvancePeriod = diffDays <= 15;
            }
          }
        }
        // console.log('inAdvancePeriod', diffDays, tenure, nextBillingAt, inAdvancePeriod);
        localStorage.setItem(
          'organisation_inAdvancePeriod',
          inAdvancePeriod || false
        );
      }

      localStorage.setItem(
        'is_callback_requested',
        !!res?.organisation?.sent_request_call_back
      );
      localStorage.setItem(
        'was_plan_canceled',
        !!res?.organisation?.plan_cancelled_at
      );
      localStorage.setItem(
        'is_third_party_channel_partner',
        res?.organisation?.channelPartner ===
        Constants.CHANNEL_PARTNERS.SHIP_ROCKET
      );
      localStorage.setItem(
        'show_payment_history',
        res?.organisation?.showPaymentHistory
      );
      localStorage.setItem('is_plan_available', !!res.organisation.plan_id);
      localStorage.setItem('organisation_status', res.organisation.status);
      localStorage.setItem('is_sms_webhook_enabled', is_sms_webhook_enabled);
      localStorage.setItem('is_call_webhook_enabled', is_call_webhook_enabled);
      localStorage.setItem(
        'is_voice_api_webhook_enabled',
        is_voice_api_webhook_enabled
      );
      localStorage.setItem('is_free_form_enabled', is_free_form_enabled);
      localStorage.setItem(
        'is_number_masking_enabled',
        is_number_masking_enabled
      );
      localStorage.setItem(
        'is_dialer_widget_enabled',
        is_dialer_widget_enabled || false
      );
      localStorage.setItem('rx_pacing_ratio', res.organisation.pacingRatio);
      if (res?.organisation?.add_ons?.is_recovery_x_enabled) {
        const recoveryCallsStartTime =
          res?.organisation?.recovery_timings?.recoveryCallsStartTime ||
          '08:00';
        const recoveryCallsEndTime =
          res?.organisation?.recovery_timings?.recoveryCallsEndTime || '19:00';
        localStorage.setItem(
          'recovery_calls_start_time',
          recoveryCallsStartTime
        );
        localStorage.setItem('recovery_calls_end_time', recoveryCallsEndTime);
        const [startTimeHour, startTimeMinutes] =
          recoveryCallsStartTime.split(':');
        const [endTimeHour, endTimeMinutes] = recoveryCallsEndTime.split(':');

        const currentTime = moment().tz('Asia/Kolkata');
        const startTime = moment()
          .tz('Asia/Kolkata')
          .hour(startTimeHour)
          .minute(startTimeMinutes);
        const endTime = moment()
          .tz('Asia/Kolkata')
          .hour(endTimeHour)
          .minute(endTimeMinutes);

        const isInBetween = currentTime.isBetween(startTime, endTime);

        localStorage.setItem(
          'is_valid_time_to_make_recovery_calls',
          isInBetween
        );
      }
      if (res?.organisation?.add_ons?.is_campaign_enabled) {
        const isDynamicCampaignEnabledForOrg =
          res?.organisation?.campaign_add_ons?.isDynamicCampaignEnabledForOrg ||
          false;
        const isPreviewDialerEnabledForOrg =
          res?.organisation?.campaign_add_ons?.isPreviewDialerEnabledForOrg ||
          false;
        const isPredictiveDailerEnabledForOrg =
          res?.organisation?.campaign_add_ons
            ?.isPredictiveDailerEnabledForOrg || false;
        const isV2StaticCampaignsEnabledForOrg =
          res?.organisation?.campaign_add_ons
            ?.isV2StaticCampaignsEnabledForOrg || false;
        const isV2DynamicCampaignsEnabledForOrg =
          res?.organisation?.campaign_add_ons
            ?.isV2DynamicCampaignsEnabledForOrg || false;
        const isOrgAssignedDidRandomizerEnabledForOrg =
          res?.organisation?.campaign_add_ons
            ?.isDidRandomizationFromOrgAssignedDids || false;
        localStorage.setItem(
          'is_dynamic_campaign_enabled',
          isDynamicCampaignEnabledForOrg
        );
        localStorage.setItem(
          'is_preview_dailer_enabled',
          isPreviewDialerEnabledForOrg
        );
        localStorage.setItem(
          'is_predictive_dailer_enabled',
          isPredictiveDailerEnabledForOrg
        );
        localStorage.setItem(
          'is_v2_static_campaigns_enabled',
          isV2StaticCampaignsEnabledForOrg
        );
        localStorage.setItem(
          'is_v2_dynamic_campaigns_enabled',
          isV2DynamicCampaignsEnabledForOrg
        );
        localStorage.setItem(
          'is_organisation_did_randomization_enabled',
          isOrgAssignedDidRandomizerEnabledForOrg
        );
      }

      let isCloudCallEnabled =
        res?.organisation?.add_ons.is_cloud_call_center_enabled || false;
      localStorage.setItem('is_cloud_call_center_enabled', isCloudCallEnabled);
      // cloud call Keys

      if (isCloudCallEnabled) {
        const {
          isCCPredictiveEnabled,
          isCCPreviewEnabled,
          isCCStaticEnabled,
          isCloudWebrtcEnabled,
          isMultiPersistConnectionEnabled,
          isPstnEnabled,
          isStaticDidEnabled,
          isPersistentConnectionEnabled,
        } = res?.organisation?.cloud_call_center_features;
        localStorage.setItem(
          'is_cc_predictive_dialer_enabled',
          isCCPredictiveEnabled
        );
        localStorage.setItem(
          'is_cc_preview_dialer_enabled',
          isCCPreviewEnabled
        );
        localStorage.setItem('is_cc_static_dialer_enabled', isCCStaticEnabled);
        localStorage.setItem('is_cc_webrtc_enabled', isCloudWebrtcEnabled);
        localStorage.setItem(
          'is_cc_multi_persist_connection_enabled',
          isMultiPersistConnectionEnabled
        );
        localStorage.setItem(
          'is_persist_connection_enabled',
          isPersistentConnectionEnabled
        );
        localStorage.setItem('is_cc_pstn_enabled', isPstnEnabled);
        localStorage.setItem('is_cc_static_dids_enabled', isStaticDidEnabled);
      }
    })
    .catch((err) => {
      console.log(err);
    });

  GET(broadcastUrl + 'v1/voice-broadcast/credits', {
    organisationId: localStorage.getItem('doosra-biz-organisation-id'),
  })
    .then((res) => {
      return res.data.response;
    })
    .then((res) => {
      const data = { ...res };
      dispatch({
        type: TOPBAR_VOICE_CREDITS_DATA_SUCCESS,
        payload: data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
