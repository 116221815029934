import Sidebar from '../../../common/componentV2/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import { commonStyle } from '../../../assets/css/css';
import { connect } from 'react-redux';
import '../index.css';
import reportDownloadIcon from "../../../assets/images/reportDownloadIcon.svg";
import reportPendingIcon from "../../../assets/images/reportPendingIcon.svg";
import { CreateReportPopup } from '../component/CreateReportPopup';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GET, POST } from '../../../services/HttpRequests';
import { toast } from 'react-toastify';
import PreLoader from '../../../common/component/preLoader/PreLoader';
const moment = require('moment-timezone');
const BIZ_ANALYTICS_URL = process.env.REACT_APP_ANALYTICS_API;

const reportsList = [
  {
    name: 'Call Report',
    type: 'Scheduled - Daily report',
    date: moment.tz('Asia/Kolkata').subtract(1, 'hour').format('DD/MM/YYYY'),
    time: moment.tz('Asia/Kolkata').subtract(1, 'hour').format('hh:mm a'),
    status: 'IN_PROGRESS',
    _id: 1,
  },
  {
    name: 'Agent Report',
    type: 'Scheduled - Weekly report',
    date: moment.tz('Asia/Kolkata').format('DD/MM/YYYY'),
    time: moment.tz('Asia/Kolkata').format('hh:mm a'),
    status: 'COMPLETED',
    _id: 2,
  },
  {
    name: 'Agent Report',
    type: 'Instant report',
    date: moment.tz('Asia/Kolkata').subtract(1, 'day').format('DD/MM/YYYY'),
    time: moment.tz('Asia/Kolkata').subtract(1, 'day').format('hh:mm a'),
    status: 'IN_PROGRESS',
    _id: 3,
  },
  {
    name: 'Call Report',
    type: 'Instant report',
    date: moment.tz('Asia/Kolkata').subtract(3, 'hour').format('DD/MM/YYYY'),
    time: moment.tz('Asia/Kolkata').subtract(3, 'hour').format('hh:mm a'),
    status: 'COMPLETED',
    _id: 4,
  },
];

const REPORT_STATUS_ICON = {
  'IN_PROGRESS': reportPendingIcon,
  'COMPLETED': reportDownloadIcon
}

const REPORT_STATUS_DESCRIPTION = {
  'IN_PROGRESS': 'Generating, in queue. You can view when the report is ready.',
  'COMPLETED': 'Generated, ready for view.'
}

function ReportCard({ date, time, status, reportId, callType, fromDate, toDate }) {
  const history = useHistory();
  function handleViewReportButton() {
    history.push(`/quality-management/sentiment-overview?id=${reportId}`);
  }
  return (
    <div className='ReportCard'>
      <div className='ReportCardLeft'>
        <div>
          <img src={REPORT_STATUS_ICON[status]} className='ReportIcon' />
        </div>
        <div className='ReportMainData'>
          <div className='ReportName'>
            Report - {date},&nbsp;{time}
          </div>
          <div className='ReportDescription'>
            {callType ? <span style={{ fontStyle: "normal" }}>{callType.toUpperCase()} ({fromDate} - {toDate})&nbsp;-&nbsp; </span> : null} {REPORT_STATUS_DESCRIPTION[status]}
          </div>
        </div>
      </div>
      <div className='ReportCardRight'>
        {status === "COMPLETED"
          && <div
            className="ViewReportButton"
            onClick={() => handleViewReportButton()}
          >
            View
          </div>}
      </div>
    </div>
  )
}

function QualityControlReportsList(props) {
  const [modalShow, setModalShow] = useState(false);
  const [qualityControlReports, setQualityControlReports] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchQualityControlReports = async () => {
    try {
      setIsLoading(true);
      const responseData = await GET(BIZ_ANALYTICS_URL + 'v1/report', {
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      });
      setQualityControlReports(responseData?.data?.response?.reportsData?.data);
      // setQualityControlReports(reportsList);
      setModalShow(false);
      setIsLoading(false);
    } catch (error) {
      console.log('Error in fetchQualityControlReports', error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchQualityControlReports()
  }, []);

  const formatReportFromToDateTime = (date) =>
    date ? moment(date).tz("Asia/Kolkata").format("YYYY-MM-DD, hh:mm A") : null;

  return (
    <div className={`${props.className}`}>
      <Topbar />
      <div style={{ display: 'flex', height: 'calc(100vh - 72px)' }}>
        <Sidebar />
        <div className="ContentContainer">
          <div className='ReportsPage'>
            <div className='ReportsPageHeader'>
              <div className='ReportsHeading'>Reports</div>
              <div
                className='CreateReportButton'
                onClick={() => setModalShow(true)}
              >
                CREATE REPORT
              </div>
            </div>
            <div className='ReportsPageBody'>
              {qualityControlReports.map(i =>
                <ReportCard
                  date={moment(i.created_at).tz("Asia/Kolkata").format("YYYY-MM-DD")}
                  time={moment(i.created_at).tz("Asia/Kolkata").format("hh:mm a")}
                  status={i.status}
                  reportId={i._id.toString()}
                  callType={i?.metaData?.callType || null}
                  fromDate={formatReportFromToDateTime(i?.metaData?.fromDate)}
                  toDate={formatReportFromToDateTime(i?.metaData?.toDate)}
                />)}
            </div>
          </div>

          <CreateReportPopup
            show={modalShow}
            onHide={() => setModalShow(false)}
            setIsLoading={setIsLoading}
            fetchQualityControlReports={fetchQualityControlReports}
          />
          {isLoading && <PreLoader />}
        </div>
      </div >
    </div >
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  userDetails: state.recovery.userDetails,
});

export default commonStyle(connect(mapStateToProps, {})(QualityControlReportsList));
