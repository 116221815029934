import Modal from 'react-bootstrap/Modal';
// import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import '../../../assets/css/Popups.css';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { GET, POST } from '../../../services/HttpRequests';
const moment = require('moment-timezone');

const BIZ_ANALYTICS_URL = process.env.REACT_APP_ANALYTICS_API;

const SCREEN_MODES = {
  EDIT: 'EDIT',
  FREEZE: 'FREEZE',
  CREDITPOPUP: 'CREDITPOPUP'
}

export function CreateReportPopup(props) {
  const [callType, setCallType] = useState('outgoing');
  const [callCount, setCallCount] = useState(1);
  const [countType, setCountType] = useState('count');
  const [callSecondsLimit, setCallSecondsLimit] = useState(10);

  const todayDate = moment.tz('Asia/Kolkata').format("YYYY-MM-DD");
  const [fromDate, setFromDate] = useState(todayDate);
  const [toDate, setToDate] = useState(todayDate);
  const [screenMode, setScreenMode] = useState(SCREEN_MODES.EDIT);
  const [precomputedData, setPrecomputedData] = useState({
    count: 0,
    credits: 0
  })

  const resetPopupValues = () => {
    setCallType('outgoing');
    setCallCount(1);
    setFromDate(todayDate);
    setToDate(todayDate);
    setCountType('count');
    setCallSecondsLimit(10);
    setPrecomputedData({
      count: 0,
      credits: 0
    })
    setScreenMode(SCREEN_MODES.EDIT);
  }

  const createQualityControlReport = async (data) => {
    try {
      const data = {
        callType,
        fromDate,
        toDate,
        countType,
        inputCount: parseInt(callCount),
        secLimit: parseInt(callSecondsLimit),
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      };
      props.setIsLoading(true);
      await POST(BIZ_ANALYTICS_URL + 'v1/report/analyze', data);
      toast.success('Analysing process started');
      await props.fetchQualityControlReports();
      resetPopupValues();
    } catch (error) {
      toast.error("Unable to analyze currently");
      props.setIsLoading(false);
    }
  }

  const verifyQualityControlReport = async (data) => {
    try {
      const data = {
        callType,
        fromDate,
        toDate,
        countType,
        inputCount: parseInt(callCount, 10),
        secLimit: parseInt(callSecondsLimit),
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      };
      props.setIsLoading(true);
      const response = await GET(BIZ_ANALYTICS_URL + 'v1/report/pre-compute/credits-deduction', data);
      const responseData = response?.data?.data;
      if (responseData?.creditsToDeduct && responseData?.finalCount) {
        setPrecomputedData({
          count: responseData.finalCount,
          credits: responseData.creditsToDeduct
        })
      }
      if (responseData?.isMatchingCount) {
        setScreenMode(SCREEN_MODES.CREDITPOPUP);
      } else {
        setScreenMode(SCREEN_MODES.FREEZE);
      }
      props.setIsLoading(false);
    } catch (error) {
      toast.error("Unable to analyze currently");
      props.setIsLoading(false);
    }
  }

  const goToCreditPopupScreenMode = () => {
    if (precomputedData.count && precomputedData.credits) {
      setScreenMode(SCREEN_MODES.CREDITPOPUP);
    } else {
      toast.error('No Data to analyze');
    }
  }

  const goToEditScreenMode = () => {
    setScreenMode(SCREEN_MODES.EDIT);
  }

  const cancelPopup = () => {
    resetPopupValues();
    props.onHide();
  }
  return (
    <Modal
      {...props}
      centered
      className='CreateReportPopup'
    >
      <div className='PopupCloseIcon'
        onClick={() => cancelPopup()}
      >x</div>
      <div className='CreateReportHead'>
        {screenMode === SCREEN_MODES.EDIT && "Create Report"}
        {screenMode === SCREEN_MODES.FREEZE && "Insufficient Data"}
        {screenMode === SCREEN_MODES.CREDITPOPUP && "Approve Credit Deduction"}
      </div>
      <div className='CreateReportBody'>
        {
          (screenMode === SCREEN_MODES.EDIT || screenMode === SCREEN_MODES.FREEZE)
          && <>
            <div className='CreateReportCallType'>
              <div style={{ paddingRight: '20px' }}>
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: '16px',
                    color: '#939598',
                    marginTop: '-6px',
                  }}
                >
                  Call Type
                </div>
                <div>
                  <select
                    value={callType}
                    disabled={screenMode === SCREEN_MODES.FREEZE}
                    onChange={e => setCallType(e.target.value)}
                    style={{
                      marginTop: '1px',
                      padding: '12px',
                      border: '1px solid #939598',
                      borderRadius: '4px',
                      width: '440px',
                      paddingLeft: '20px',
                      fontWeight: 500,
                      fontSize: '16px',
                      color: '#939598',
                    }}
                  >
                    <option value='outgoing'>Outgoing</option>
                    <option value='incoming'>Incoming</option>
                  </select>
                </div>
              </div>
            </div>

            <div className='CreateReportDates'>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '24px',
                }}
              >
                <div style={{ paddingRight: '20px' }}>
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: '16px',
                      color: '#939598',
                      marginTop: '-8px',
                    }}
                  >
                    From Date
                  </div>
                  <div>
                    <input
                      value={fromDate}
                      disabled={screenMode === SCREEN_MODES.FREEZE}
                      onChange={e => setFromDate(e.target.value)}
                      style={{
                        padding: '10px',
                        border: '1px solid #939598',
                        borderRadius: '4px',
                        width: '200px',
                        paddingLeft: '20px',
                      }}
                      type="date"
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: '20px' }}>
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: '16px',
                      color: '#939598',
                      marginTop: '-8px',
                    }}
                  >
                    To Date
                  </div>
                  <div>
                    <input
                      value={toDate}
                      disabled={screenMode === SCREEN_MODES.FREEZE}
                      onChange={e => setToDate(e.target.value)}
                      style={{
                        padding: '10px',
                        border: '1px solid #939598',
                        borderRadius: '4px',
                        width: '200px',
                        paddingLeft: '20px',
                      }}
                      type="date"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='CreateReportCallsNumericalType'>
              <div style={{ paddingRight: '20px' }}>
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: '16px',
                    color: '#939598',
                    marginTop: '-6px',
                  }}
                >
                  Count Type
                </div>
                <div>
                  <select
                    value={countType}
                    disabled={screenMode === SCREEN_MODES.FREEZE}
                    onChange={e => setCountType(e.target.value)}
                    style={{
                      marginTop: '1px',
                      padding: '12px',
                      border: '1px solid #939598',
                      borderRadius: '4px',
                      width: '440px',
                      paddingLeft: '20px',
                      fontWeight: 500,
                      fontSize: '16px',
                      color: '#939598',
                    }}
                  >
                    <option value='percentage'>Percentage</option>
                    <option value='count'>Count</option>
                  </select>
                </div>
              </div>
            </div>

            <div className='CreateReportCallsCount'>
              <div style={{ paddingRight: '20px' }}>
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: '16px',
                    color: '#939598',
                    marginTop: '-6px',
                  }}
                >
                  {countType === 'count' ? 'Number of calls' : 'Percentage of calls(%)'}
                </div>
                <div>
                  <input
                    type='number'
                    min={1}
                    max={1000}
                    disabled={screenMode === SCREEN_MODES.FREEZE}
                    value={callCount}
                    onChange={e => setCallCount(e.target.value)}
                    style={{
                      marginTop: '1px',
                      padding: '12px',
                      border: '1px solid #939598',
                      borderRadius: '4px',
                      width: '440px',
                      paddingLeft: '20px',
                      fontWeight: 500,
                      fontSize: '16px',
                      color: '#939598',
                    }}
                  />
                </div>
              </div>
            </div>

            <div className='CreateReportCallsLowerLimit'>
              <div style={{ paddingRight: '20px' }}>
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: '16px',
                    color: '#939598',
                    marginTop: '-6px',
                  }}
                >
                  Call Seconds Lower Limit
                </div>
                <div>
                  <select
                    value={callSecondsLimit}
                    disabled={screenMode === SCREEN_MODES.FREEZE}
                    onChange={e => setCallSecondsLimit(e.target.value)}
                    style={{
                      marginTop: '1px',
                      padding: '12px',
                      border: '1px solid #939598',
                      borderRadius: '4px',
                      width: '440px',
                      paddingLeft: '20px',
                      fontWeight: 500,
                      fontSize: '16px',
                      color: '#939598',
                    }}
                  >
                    {[10, 30, 60].map(i => <option value={i}>{i}</option>)}
                  </select>
                </div>
              </div>
            </div>

          </>}

        {screenMode === SCREEN_MODES.CREDITPOPUP &&
          <div
            className='ApproveCreditDeductionBody'
          >
            This quality analysis report will deduct <b style={{ color: "#196cca" }}>{`< ${precomputedData?.credits || 0} credits >`}</b> from your available credits.
            Press <b style={{ color: "#196cca" }}>Confirm</b> to proceed.
          </div>}

        {screenMode === SCREEN_MODES.EDIT &&
          <>
            <div
              className='CreateReportSaveButton'
              onClick={() => verifyQualityControlReport()}
            >
              Create
            </div>
            <div
              className='CreateReportCancelButton'
              onClick={() => cancelPopup()}
            >
              Cancel
            </div>
          </>
        }

        {screenMode === SCREEN_MODES.FREEZE &&
          <>
            <div
              className='InsufficientDataNote'
            >
              <b>Note:</b> We found only <b>{precomputedData?.count || 0}</b> matching your selection criteria. Would you like to proceed with this data or edit your criteria?
            </div>
            <div
              className='CreateReportSaveButton'
              onClick={() => goToCreditPopupScreenMode()}
            >
              Proceed
            </div>
            <div
              className='CreateReportEditButton'
              onClick={() => goToEditScreenMode()}
            >
              Edit
            </div>
          </>
        }

        {screenMode === SCREEN_MODES.CREDITPOPUP &&
          <>
            <div
              className='CreateReportConfirmButton'
              onClick={() => createQualityControlReport()}
            >
              Confirm
            </div>
          </>
        }


      </div>

    </Modal >
  );
}