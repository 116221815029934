import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import Paginator from '../../../../common/component/pagination/PaginationComponent';

function LiveCallsTable(props) {
  const [agentsData, setAgentsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);

  useEffect(() => {
    setAgentsData(props?.data?.liveCallsData || []);
    setCurrentPage((props?.data?.pageNo + 1) || 0);
    setTotalPages(Math.ceil((props?.totalPages || 0) / 15));
  }, [props?.data]);

  return (
    <div>
      <TableContainer style={{ overflow: "auto" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className='AgentStatusTableHeader'>Agent name</TableCell>
              <TableCell className='AgentStatusTableHeader'>Agent number</TableCell>
              <TableCell className='AgentStatusTableHeader'>Contact name</TableCell>
              <TableCell className='AgentStatusTableHeader'>Contact number</TableCell>
              <TableCell className='AgentStatusTableHeader'>Call type</TableCell>
              <TableCell className='AgentStatusTableHeader' align='right' style={{ width: "30%" }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className='AgentStatusTableBody'>
            {agentsData.map((i, index) => (
              <TableRow key={i._id} style={index % 2 === 1 ? {
                background: 'rgba(175, 82, 222, 0.05)'
              } : {}}>
                <TableCell className='AgentStatusTableContent'>{i.AgentName}</TableCell>
                <TableCell className='AgentStatusTableContent'>{i.agentNumber}</TableCell>
                <TableCell className='AgentStatusTableContent'>{i.contactName}</TableCell>
                <TableCell className='AgentStatusTableContent'>{i.customerNumber}</TableCell>
                <TableCell className='AgentStatusTableContent'>{'outgoing'}</TableCell>
                <TableCell className='AgentStatusTableContent' align='right' style={{ width: "30%" }}>
                  Listen
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[15]}
        component="div"
        count={15}
        rowsPerPage={15}
        page={1}
      /> */}
      <Paginator
        currentPage={currentPage}
        lastPage={totalPages}
        getInfo={(value) => {
          props.fetchData({ pageNo: value });
        }}
      />
    </div>
  )
}

export default LiveCallsTable